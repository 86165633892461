import FaqAccordionSection from "../components/sections/FaqAccordionSection/FaqAccordionSection"
import IllustrationHeader from "../components/global/IllustrationHeader"
import Layout from "../components/global/Layout"
import React from "react"
import TwoCol_AccountBreakdownSection from "../components/page-specific/Get-Started/TwoCol_AccountBreakdownSection/TwoCol_AccountBreakdownSection"
import GHPricingBreakdown from "../components/page-specific/Corporate-Accounts-Pricing/GHPricingBreakdown/GHPricingBreakdown";
import GHCtaSection from "../components/sections/GHCtaSection/GHCtaSection";
import GHFaqAccordionSection from "../components/sections/GHFaqAccordionSection/GHFaqAccordionSection";
import GHValuePropsCustomBox from "../components/sections/GHValuePropsCustomBox/GHValuePropsCustomBox";
import GHGeneralHeader from "../components/global/GHGeneralHeader/GHGeneralHeader";
import GHLayout from "../components/global/GHLayout/GHLayout";

const CorporateAccountsPricing = ( { location } ) => {

    const imageSizesValueProps = {
        maxWidth: "249px",
        maxHeight: "173px"
    }


    const valueProps = [
        {
            headline: "Enjoy flexibility",
            subheadline: "Create a meal plan tailored to fit  your team’s size and budget on the industry’s most flexible corporate ordering platform.",
            imageSrc: "/images/pages/corp-pricing/enjoy_flexibility.svg",
            imageSize: imageSizesValueProps,
        },
        {
            headline: "Control costs and save more",
            subheadline: "Set cost-effective ordering rules for your team and only pay for the amount spent by your employees.",
            imageSrc: "/images/pages/corp-pricing/control_cost.svg",
            imageSize: imageSizesValueProps,
        },
        {
            headline: "Get exclusive deals",
            subheadline: "Unlock cost-effective solutions that will stretch your budget.",
            imageSrc: "/images/pages/corp-pricing/get_exclusive_deals.svg",
            imageSize: imageSizesValueProps,
        },
    ]


    const faqs = [
        {
            header: "How much does a Grubhub Corporate Account cost?",
            description: `<span>A <a href="https://corporate.grubhub.com/get-started/" target="_blank">Grubhub Corporate Account</a>  is completely free to set up. It is up to you on how much you spend. Our corporate ordering solution offers a variety of customization, budget control and reporting features so you never have to overspend.</span>`
        },
        {
            header: "What should I do if I’m not sure which Corporate Account is best for me?",
            description: `<span>We’re here to help you choose which plan is right for you. Simply <a href="https://corporate.grubhub.com/contact-us/" target="_blank">contact us</a> and we will be in touch to discuss your corporate ordering needs.</span>`
        },
        {
            header: "Why are meal perks with a Grubhub Corporate Account a cost-effective employee benefit?",
            description: `<span>Our corporate ordering solution offers a variety of <a href="https://corporate.grubhub.com/blog/why-meal-allowances-are-a-cost-effective-employee-benefit/" target="_blank">customization, budget control and reporting</a> features so you never have to overspend. You can build a custom and flexible program that suits your business needs. Set a specific budget per person and only pay for what’s actually ordered, saving you a significant amount of money in the long run.</span>`
        }
    ];
    const leftColBullets = [
        {
            headline: "COST",
            bullets: [
                {
                    text: "Free to sign up and use",
                    subtext: "(Only pay for the amount spent.)"
                },
            ]
        },
        {
            headline: "Ordering Type",
            bullets: [
                {
                    text: "Individual meal perks"
                },
                {
                    text: "Scheduled team orders",
                    subtext: "(No diner or order minimums. Admins set budgets and send out restaurant choices.)"
                },
                {
                    text: "Group orders",
                    subtext: "(Anyone on your team can start a shared cart and split meal costs with colleagues. Plus there's no minimums for number of diners or orders.)"
                },
                {
                    text: "Catering",
                }
            ]
        },
        {
            headline: "Payment",
            bullets: [
                {
                    text: "Invoice",
                    subtext: "(Order now, pay later via check/ACH, wire, credit card)"
                },
                {
                    text: "Credit card",
                    subtext: "(Charged when orders are placed)",
                    inactive: true,
                    textgrey: true

                }
            ]
        },
        {
            headline: "ACCOUNT SUPPORT",
            bullets: [
                {
                    text: "Corporate care",
                },
                {
                    text: "Dedicated client success team",
                }
            ]
        },
        {
            headline: "SPENDING",
            bullets: [
                {
                    text: "Budget customization",
                }
            ]
        },
        {
            headline: "REPORTING",
            bullets: [
                {
                    text: "Detailed receipts",
                },
                {
                    text: "Expense codes",
                },
                {
                    text: "Cost comments",
                }
            ]
        },
        {
            headline: "SETUP METHOD",
            bullets: [
                {
                    text: "Self-service",
                    inactive: true,
                    textgrey: true
                },
                {
                    text: "Sales walkthrough",
                }
            ]
        }
    ]
    const rightColBullets = [
        {
            headline: "COST",
            bullets: [
                {
                    text: "Free to sign up and use",
                    subtext: "(Only pay for the amount spent.)"
                },
            ]
        },
        {
            headline: "Ordering Type",
            bullets: [
                {
                    text: "Individual meal perks",
                },
                {
                    text: "Scheduled team orders",
                    subtext: "(No diner or order minimums. Admins set budgets and send out restaurant choices.)",
                    inactive: true,
                    textgrey: true
                },
                {
                    text: "Group orders",
                    subtext: "(Anyone on your team can start a shared cart and split meal costs with colleagues. Plus there's no minimums for number of diners or orders.)"
                },
                {
                    text: "Catering",
                    inactive: true,
                    textgrey: true
                }
            ]
        },
        {
            headline: "Payment",
            bullets: [
                {
                    text: "Invoice",
                    subtext: "(Order now, pay later via check/ACH, wire, credit card)",
                    inactive: true,
                    textgrey: true
                },
                {
                    text: "Credit card",
                    subtext: "(Charged when orders are placed)",
                }
            ]
        },
        {
            headline: "ACCOUNT SUPPORT",
            bullets: [
                {
                    text: "Corporate care",
                },
                {
                    text: "Dedicated client success team",
                    inactive: true,
                    textgrey: true
                }
            ]
        },
        {
            headline: "SPENDING",
            bullets: [
                {
                    text: "Budget customization",
                }
            ]
        },
        {
            headline: "REPORTING",
            bullets: [
                {
                    text: "Detailed receipts",
                },
                {
                    text: "Expense codes",
                    inactive: true,
                    textgrey: true
                },
                {
                    text: "Cost comments",
                    inactive: true,
                    textgrey: true
                }
            ]
        },
        {
            headline: "SETUP METHOD",
            bullets: [
                {
                    text: "Self-service"
                },
                {
                    text: "Sales walkthrough",
                    inactive: true,
                    textgrey: true
                }
            ]
        }
    ]

    const pricing = {
        leftColumn: {
            headline: "Enterprise Account",
            text: "A dedicated team is ready to help build your custom meal perk solution and support every step of the way! Perfect for large groups and daily or weekly ordering.",
            buttonLabel: "Get a demo",
            buttonLink: "/contact-us-enterprise-account/",
            list: leftColBullets

        },
        rightColumn: {
            headline: "Self-Service Account",
            text: "Start building your own meal plan and feed your team immediately after signing up! Great for smaller groups, celebrations at work, client meetings, and more.",
            buttonLabel: "Create your account",
            buttonLink: "https://www.grubhub.com/corporate/signup?utm_source=corporate.grubhub.com&utm_medium=content_owned&utm_campaign=corpselfservice_signup_getstarted&utm_content=getstartedCTA",
            list: rightColBullets

        },
    };

    return (
        <GHLayout
            location={location}
            title={"Pricing | Grubhub Corporate Accounts"}
            description="It’s free to get started with a Grubhub Corporate Account. Stretch your budget and only pay for the meals actually ordered!"
            utag_data={{
                pageGroup: "grubhub - lead generation",
                subGroup: "two ways to get started",
                pageName: "two ways to get started",
                brand: "grubhub"
            }}
            faqSeoList={faqs}
        >
    <GHGeneralHeader
        headline="A custom meal program with<br/> no sign up costs."
        headlineMaxWidth={"600px"}
        subheadline="It's free to sign up and there are no corporate transaction fees. Only pay for the amount spent on Grubhub."
        subheadlineStyle={{marginTop: "16px"}}
        textColor="#242E30"
        background="rgba(16,143,87,0.1)"
        backgroundColorForm='#FFF'
        type="full-image"//"half-image"
        alt="Grubhub delivery driver walking carrying two orange food bags."
        imageSrc="/images/pages/corp-pricing/corporate_pricing_header_background.png"
        imageSrcMobile="/images/pages/corp-pricing/corporate_pricing_header_background_mobile.png"
        mobileHeight={"1060px"}
        ctaLink={"https://corporate.grubhub.com/get-started/"}
        ctaText={"Sign up"}
        ctaStyle={{marginRight: "auto"}}
        secondaryCtaLink={"https://corporate.grubhub.com/contact-us/"}
        secondaryCtaText={"Talk to Sales"}
    />
       <GHValuePropsCustomBox
           headline="Why try a Grubhub Corporate Account?"
           textColor={"#ffffff"}
           backgroundColor={"#ff8800"}
           valueProps={valueProps}
           imageWrapperHeight={"188px"}
           layout="vertical"
           theme="catering"
           maxWidth="1024px"
           bottomGraphic={true}
           bottomGraphicNegativeColor={"#F5F3F1"}
           style={{ paddingBottom: "100px" }}
       />

        <GHPricingBreakdown //eslint-disable-line
            backgroundColor={"#F5F3F1"}
            headline={'Two ways to get started for free'}
            subheadline={'Corporate ordering solutions to fit your every need.'}
            pricing={pricing}
        />
      <GHCtaSection
          buttonType="secondary"
          ctaText="Learn more"
          to="/contact-us/"
          headline="Deliver happiness to your team today"
          subheadline={'Get started with Grubhub Corporate Accounts.'}
          backgroundColor="#C1DADE"
          maxWidth="548px"
          topSpacing={"0px"}
          topSpacingMobile={"0"}
          bottomSpacing={"100px"}
          bottomSpacingMobile={"20px"}
          graphicTopSrc={'/images/pages/corp-pricing/burger-image.png'}
          graphicTopStyle={{backgroundSize:"cover", backgroundRepeat: "no-repeat"}}
          graphicBottomSrc={'/images/pages/corp-pricing/curry-image.png'}
          graphicBottomStyle={{ backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
      />
      <GHFaqAccordionSection faqs={faqs} backgroundColor="white"/>
    </GHLayout>
    )
}

export default CorporateAccountsPricing
